if (module.hot) {
  module.hot.accept();
}

$(document).ready(function() {
  $('a[href*=#]').bind('click', function(e) {
      e.preventDefault(); // prevent hard jump, the default behavior

      var target = $(this).attr("href"); // Set the target as variable

      // perform animated scrolling by getting top-position of target-element and set it as scroll target
      $('html, body').stop().animate({
          scrollTop: $(target).offset().top
      }, 600, function() {
          location.hash = target; //attach the hash (#jumptarget) to the pageurl
      });

      return false;
  });

  $(".menu-opener").on("click", function(){
    $(".main-nav").slideToggle();
    $(this).toggleClass('open');
  });

  $( '.main-nav a' ).on("click", function(){
    if ($( window ).width() <= 1024) {
      $(".main-nav").slideToggle();
      $(".menu-opener").removeClass('open');
    }
  });
});

$(window).scroll(function() {
  var scrollDistance = $(window).scrollTop();

  // Assign active class to nav links while scolling
  $('.info').each(function(i) {
      if ($(this).position().top <= scrollDistance) {
          $('.main-nav a.active').removeClass('active');
          $('.main-nav a').eq(i).addClass('active');
      }
  });
}).scroll();

$( "#subscribeForm" ).submit(function( event ) {
  const email = $('#email').val();
  const name = $('#name').val();
  const phone = $('#phone').val();
  window.location.href = "mailto:support@globalpay.cash?subject=" + "Message from globalpay.cash" + "&body=" + name + ' ' + email + ' ' + phone;
  event.preventDefault();
});
